<template lang="pug">
.page(style="font-family:胡晓波男神体")
  | this is page content 胡晓波男神体
.content(style="font-family:胡晓波骚包体")
  | this is page content 胡晓波骚包体
.content(style="font-family:胡晓波真帅体")
  | this is page content 胡晓波真帅体
.content(style="font-family:优设标题黑")
  | this is page content 优设标题黑
.content(style="font-family:Source Han Sans CN")
  | this is page content 思源黑体
.content(style="font-family:Microsoft YaHei")
  | this is page content 微软雅黑
.content(style="font-family:FZFangSong-Z02S")
  | this is page content 方正仿宋简体
.content(style="font-family:SimHei")
  | this is page content 黑体
.content(style="font-family:STXihei")
  | this is page content 细黑
.content(style="font-family:SimSun")
  | this is page content 新宋
.content
  a-button(type="danger" ) +++
.content
  a-button(type="primary" @click="handleFetch") fetch
  a-button(type="primary" @click="handleFetch2") fetch2
  a-button(type="primary" @click="handleBackToMain") back to amin

</template>

<script setup>
import { getCurrentInstance, inject, onMounted } from 'vue'
const mainRouter = window.$mainRouter
const { fetch } = inject('global')

onMounted(() => {})

const handleBackToMain = () => {
  mainRouter.push('/')
}
const handleFetch = () => {
  fetch.post('/generate', {})
}
const handleFetch2 = () => {
  fetch.post('http://localhost:7001/dev/ping', {})
}
</script>

<style lang="less" scoped>
.page {
  width: 100%;
}
</style>
